import owner from './owner.jpeg'

import './index.css'

const AboutMe = () => {
    return (
            <div className='content'>
                <img src={owner} alt='owner'></img>
                <h1>Nick Jung</h1>
                <div className='list-container'>
                    <ul>
                        <li>SIUe - B.S. Exercise Science/Kinesiology</li>
                        <li>TRX Functional Training</li>
                        <li>ACSM- Exercise Physiology - Certified</li>
                    </ul>
                </div>
                <p>I am a personal trainer with 5 years experience in the fitness industry, and I specialize in functional training.  This includes injury prevention, sports performance, and general strength.</p>
            </div>
    );
};

export default AboutMe;