import HomePageHeader from "./HomePageHeader";
import WeightliftGraphic from "./Weightlifting";

import './index.css'
import Icons from "./Icons";
import HomePageFooter from "./HomePageFooter";

const HomePage = () => {
    return (
        <div className='home-content'>
            <HomePageHeader />
            <WeightliftGraphic />
            <Icons />
            <HomePageFooter />
        </div>
    );
};

export default HomePage;