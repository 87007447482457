import './index.css'

const MainFooter = () => {
    return (
        <footer>
            <p>
                &copy; {(new Date()).getFullYear()} SPF Fitness
                | Website by <span onClick={()=> window.open("http://www.amdwebdesign.net", "_blank")}>AMD Web Design</span>
            </p>
        </footer>
    )
}

export default MainFooter;