import MonthlySchedule from "../components/MonthlySchdule";
const Calendar = () => {
    return (
        <section>
            <h1>Calendar</h1>
            <MonthlySchedule />
        </section>
    );
};

export default Calendar;