import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaseballBall, faDumbbell, faRunning } from '@fortawesome/free-solid-svg-icons'

import './index.css'

const Icon = (props) => {
    return (
        <div className="wrapper">
            <div className='icon-container'>
                <FontAwesomeIcon icon={props.icon} className="icon"/>
            </div>
            <div className='caption'>{props.caption}</div>
        </div>
    );
}

const Icons = () => {
    return (
        <div className='icons'>
            <Icon icon={faDumbbell} caption="Strength"/>
            <Icon icon={faBaseballBall} caption="Performance"/>
            <Icon icon={faRunning} caption="Functionality"/>
        </div>
    );
};

export default Icons;