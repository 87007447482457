import { Route, Switch } from 'react-router-dom';
import React from 'react';
import AboutMe from './views/AboutMe';
import Home from './views/Home';
import MainHeader from './components/MainHeader';
import MainFooter from './components/MainFooter';
import Calendar from './views/Calendar';
import ClassDescriptions from './views/ClassDescriptions';

function App() {    
  const [state, updateState] = React.useState({
    hideDropdownNav: true
  });
  const updateAppState = () => updateState({hideDropdownNav: !state.hideDropdownNav});
  return (
      <div className='main-content'>
        <MainHeader updateAppState={updateAppState} hideDropdownNav={state.hideDropdownNav}/>
        <main>
          <Switch>
            <Route path="/" exact>
              <Home /> 
            </Route>
            <Route path="/about-me" exact>
              <AboutMe /> 
            </Route>
            <Route path="/calendar" exact>
              <Calendar /> 
            </Route>
            <Route path="/class-descriptions" exact>
              <ClassDescriptions /> 
            </Route>
            <Route>
              <h1>Default 404</h1>
            </Route>
          </Switch>
        </main>
        <MainFooter />
      </div>
  );
}

export default App;
