import calendar from './calendar.png'

import './index.css'

const MonthlySchedule = () => {
    return (
            <div>
                <img className="calendar" src={calendar} alt='calendar'></img>
            </div>
    );
};

export default MonthlySchedule;