import Classes from "../components/Classes";
const ClassDescriptions = () => {
    return (
        <section>
            <h1>Class Descriptions</h1>
            <Classes />
        </section>
    );
};

export default ClassDescriptions;