import weightlift from './weightlift.jpeg'

import './index.css'

const WeightliftGraphic = () => {
    return (
        <div>
            <img src={weightlift} alt="Weightlifting graphic" className="wide-img"/>
        </div>
    );
};

export default WeightliftGraphic;