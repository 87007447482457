import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';

// const basename = window.location.hostname.includes('gitlab') ? 'front-end/#' : '#';
// if (!window.location.pathname.endsWith('/')) {
//     window.location.pathname = window.location.pathname + '/'
// }
// // If its at localhost, then 
// if (window.location.hostname.includes('gitlab') && window.location.pathname.startsWith('/front-end') && !window.location.pathname.startsWith('/front-end/')) {
//     console.log(window.location.pathname);
//     console.log(window.location.hash);
//     window.location.pathname = '/front-end/';
// }


const basename = window.location.hostname.includes('gitlab') ? 'front-end/' : ''


const index = basename ? 1 : 3;
let path_starts_with_hash = ''

path_starts_with_hash = window.location.href.split('/' + basename)[index].startsWith('#')

if (!path_starts_with_hash ) {
    const newHrefArray = window.location.href.split('/'+ basename)
    newHrefArray.splice(index, 0, basename + '#')
    window.location.href = newHrefArray.join('/')
}

ReactDOM.render(<Router basename={'front-end/#'}><App /></Router>, document.getElementById('root'));
