import validator from 'validator';
import axios from 'axios'
import React from 'react';
import './index.css';

const isFormValid = (name, email, message) => {
    const isNameValid = name.length > 0;
    const isEmailFromValid = validator.isEmail(email);
    const isMessageValid = message.length > 0;
    return isNameValid && isEmailFromValid && isMessageValid;
};



const ContactMe = () => {
    const [state, updateState] = React.useState({
        form: {
            emailTo: "client-1",
            name: "",
            emailFrom: "",
            message: "",
            website: "",
            status: "unsubmitted",
            valid: false
        },
        errors: {
            name: true,
            emailFrom: true,
            message: true
        }
      });

    const handleInputChange = (event) => {
        const name = event.target.name === 'name' ? event.target.value : state.form.name;
        const emailFrom = event.target.name === 'email-from' ? event.target.value : state.form.emailFrom;
        const message = event.target.name === 'message' ? event.target.value : state.form.message;
        const website = event.target.name === 'website' ? event.target.value : state.form.website;
        updateState({
            form: {
                emailTo: state.emailTo,
                name,
                emailFrom,
                message,
                website,
                valid: isFormValid(name, emailFrom, message),
                status: state.status
            },
            errors: state.errors,
        });
    }

    const send = () => {
        const sending = state.form.website === '' && isFormValid(state.form.name, state.form.emailFrom, state.form.message)
        const form = Object.assign({}, state.form, { status: sending? 'sending': state.form.status})
        const errors = {
            name: state.form.name.length > 0,
            emailFrom: validator.isEmail(state.form.emailFrom),
            message: state.form.message.length > 0 
        };
        const newState = Object.assign({}, {form, errors});
        if (sending) {
            const url = 'http://flask-env.eba-tr8nzamy.us-east-2.elasticbeanstalk.com/contact';
            const data = {
                emailTo: 'client-1',
                emailFrom: state.form.emailFrom,
                name: state.form.name,
                message: state.form.message,
            }
            axios({
                method: 'post',
                url,
                data,
            })
            .then(() => newState.form.status = 'sent')
            .catch(() => newState.form.status = 'failed')
            .then(() => updateState(newState));
        } else {
            updateState(newState);
        }
    }

    const getButtonClass = () => {
        const classes = [state.form.status]
        if (!state.form.valid) {classes.push("disabled")}
        return classes.join(" ")
    }
    
    return (
        <div className="form">
            <input type="hidden" id='email-to' name="email-to" value={state.form.emailTo}/>
            <div>
                <input type="text" id="name" name="name" placeholder="Your name" value={state.form.name} onChange={handleInputChange}/>
            </div>
            <div className={state.errors.name ? "errors hidden": "errors"}>
                Please include your name.
            </div>
            <div>
                <input type="email" id="email-from"  name="email-from" placeholder="Your email" value={state.form.emailFrom} onChange={handleInputChange}/>
            </div>
            <div className={state.errors.emailFrom ? "errors hidden": "errors"}>
                Please include a valid email.
            </div>
            <div>
                <input type="text" id="website" name="website" placeholder="Your website" value={state.form.website} onChange={handleInputChange}/>
            </div>
            <div>
                <textarea id="message" name="message" placeholder="Your message"  value={state.form.message} onChange={handleInputChange} ></textarea>
            </div>
            <div className={state.errors.message ? "errors hidden": "errors"}>
                Please include a message.
            </div>
            <br />
            <div>
                <button className={getButtonClass()} onClick={() => send(state.form)}>
                    {
                        state.form.status === 'sent'? 'Sent!':
                            state.form.status === 'failed'? 'Failed to Send': 'Send'
                    }
                </button>
            </div>
        </div>
    )
}

export default ContactMe;