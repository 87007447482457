import './index.css'

const Classes = () => {
    return (
            <div className='content'>
                <div className="fitness-class">
                    <h4>Advanced Bootcamp</h4>
                    <p>A fuctional whole-body workout utilizing different techniques--mainly interval training--to achieve improvements in mobility, flexibility, and strength while accelerating fat loss!</p>
                    <p>Our three-week rotations include emphasis in:</p>
                    <ul>
                        <li>Stability</li>
                        <li>Endurance</li>
                        <li>Strength</li>
                        <li>Power & Agility</li>
                    </ul>
                </div>
                <div className="fitness-class">
                    <h4>Starter Bootcamp</h4>
                    <p>Just like Advanced, Starter is a functional whole-body workout utilizing different techniques--mainly interval training--to achieve improvements in mobility, flexibility, and strength while accelerating fat loss!  Starter bootcamp differs from advanced in two ways: It is slightly less intense and the rotations (Stability, Endurance, and Strength) are only 2 weeks per emphasis.</p>
                </div>
            </div>
            
    );
};

export default Classes;