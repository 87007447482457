import owner from './owner-circle.jpeg'
import logo from './logo.png'

import './index.css'

const HomePageHeader = () => {
    return (
        <div>
            <div className="side-col">
                <img src={logo} alt="Logo" className="logo"/>
            </div>
            <div className="mid-col">
                <h1>SPF FITNESS</h1>
                <h4>Strength, Performance, Functionality</h4>
            </div>
            <div className="side-col">
                <img src={owner} alt="Owner Nick Jung" className="round-img"/>
            </div>
        </div>
    );
};

export default HomePageHeader;