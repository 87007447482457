import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import classes from './index.module.css';
import React from 'react';



const MainHeader = (props) => {
    // We need to rerender the whole page for this to work correctly I think because the rest of the page is not being pushed down.

    const handleButtonClick = () => {
        props.updateAppState()
    }
    return (
        <header className={classes.header}>
                
            <nav>
                <button className={classes.hamburgerContainer} onClick={handleButtonClick}>
                    <FontAwesomeIcon icon={faBars} className={classes.hamburger} />
                </button>
                {/* <ul style={props.hideDropdownNav ? {}: {display: 'block', height: '180px'} }> */}
                <ul className={props.hideDropdownNav ? classes.hideNav : classes.showNav }>
                    <li>
                        <NavLink activeClassName={classes.active} to='/' exact>
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName={classes.active} to='/about-me'>
                            About Me
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName={classes.active} to='/calendar'>
                            Calendar
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName={classes.active} to='/class-descriptions'>
                            Class Descriptions
                        </NavLink>
                    </li>
                    <li>
                        <span onClick={()=> window.open("https://www.facebook.com/SPFitness48/", "_blank")} href="https://www.facebook.com/SPFitness48/">Facebook</span>
                    </li>
                </ul>
            </nav>
        </header>
    )
}


export default MainHeader;